<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { OrbitSpinner } from 'epic-spinners';

import CopyableText from "@/components/CopyableText";
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";

export default {
  props: ['banlist_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    // eslint-disable-next-line vue/no-unused-components
    OrbitSpinner
  },
  computed: {
    ...mapGetters(['getDTLocale'])
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/verification`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.verification = data.verification;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async approveVerification() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/approve-verification`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.getData();
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
    async denyVerification() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/deny-verification`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.getData();
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
    async reworkVerification() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/rework-verification`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.getData();
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      inProgress: false,
      verification: {}
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
            <div class="col-xl-6 col-sm-12">
              <template v-if="verification.status.verified">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="text-center">
                      <div class="avatar-md mx-auto mb-4">
                        <div class="avatar-title bg-light rounded-circle ">
                          <i class="far fa-badge-check text-primary" style="font-size: 48px;" />
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-xl-10">
                          <h4 class="text-primary">Verified</h4>
                          <span class="text-muted font-size-14 mb-4">
                          <i class="far fa-clock"></i>
                          {{ $d(parseDate(verification.info.completed_at), 'date', getDTLocale()) }}
                          <hr>
                          <div class="row">
                            <div class="col">
                              <button class="btn btn-lg btn-block btn-info" :disabled="(inProgress)" v-on:click="reworkVerification()">
                                <orbit-spinner
                                    v-if="inProgress"
                                    :animation-duration="1200"
                                    :size="18"
                                    class="align-middle d-inline-block"
                                />
                                Request changes
                              </button>
                            </div>
                          </div>

                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="verification.status.unlisted">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="text-center">
                      <div class="row justify-content-center">
                        <div class="col-xl-10">
                          <h4 class="text-danger">Unlisted</h4>
                          <span class="text-muted mb-4">
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="verification.info.in_progress">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="text-center">
                      <div class="row justify-content-center">
                        <div class="col-xl-10">
                          <h4 class="text-primary">{{ $t('banmanager.view.verification.in_progress.title') }}</h4>
                          <span class="text-muted mb-4">
                          <i class="far fa-clock"></i>
                          {{ $t('banmanager.view.verification.in_progress.date') }} {{ $d(parseDate(verification.info.requested_at), 'date', getDTLocale()) }}
                          <hr>
                          <div class="row">
                            <div class="col-4">
                              <button class="btn btn-lg btn-block btn-primary" :disabled="(inProgress)" v-on:click="approveVerification()">
                                <orbit-spinner
                                    v-if="inProgress"
                                    :animation-duration="1200"
                                    :size="18"
                                    class="align-middle d-inline-block"
                                />
                                Approve
                              </button>
                            </div>
                            <div class="col-4">
                              <button class="btn btn-lg btn-block btn-danger" :disabled="(inProgress)" v-on:click="denyVerification()">
                                <orbit-spinner
                                    v-if="inProgress"
                                    :animation-duration="1200"
                                    :size="18"
                                    class="align-middle d-inline-block"
                                />
                                Deny
                              </button>
                            </div>
                            <div class="col-4">
                              <button class="btn btn-lg btn-block btn-info" :disabled="(inProgress)" v-on:click="reworkVerification()">
                                <orbit-spinner
                                    v-if="inProgress"
                                    :animation-duration="1200"
                                    :size="18"
                                    class="align-middle d-inline-block"
                                />
                                Request changes
                              </button>
                            </div>
                          </div>
                          <hr>
                          <div v-for="attempt in verification.history" :key="attempt.date">
                            <b>{{ $d(parseDate(attempt.date), 'datetime', getDTLocale()) }}</b>
                            <template v-if="attempt.outcome">
                              <span class="h6 text-success text-uppercase">
                                Approved
                              </span>
                            </template>
                            <template v-else>
                              <span class="h6 text-danger text-uppercase">
                                Denied
                              </span>
                            </template>
                          </div>
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <h1 class="text-uppercase text-muted text-center">none active</h1>
              </template>
            </div>
            <div class="col-xl-6 col-sm-12">
              <div class="card">
                <div class="card-header">
                  <template v-if="verification.requirements.fulfilled">
                    <div class="d-flex align-items-center mb-0">
                      <div class="avatar-xs mr-3">
                            <span class="avatar-title rounded-circle bg-soft-success text-success font-size-18">
                              <i class="fas fa-check"></i>
                            </span>
                      </div>
                      <h4 class="mb-0">
                        Fulfilled
                      </h4>
                    </div>
                  </template>
                  <template v-else>
                    <div class="d-flex align-items-center mb-0">
                      <div class="avatar-xs mr-3">
                            <span class="avatar-title rounded-circle bg-soft-danger text-danger font-size-18">
                              <i class="fas fa-times"></i>
                            </span>
                      </div>
                      <h4 class="mb-0">
                        Not Fulfilled
                      </h4>
                    </div>
                  </template>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="media">
                        <div class="d-inline mr-2">
                          <template v-if="verification.requirements.count">
                            <i class="fas fa-check text-success align-middle align-self-center font-size-24"></i>
                          </template>
                          <template v-else>
                            <i class="fas fa-times text-danger align-middle align-self-center font-size-24"></i>
                          </template>
                        </div>
                        <div class="d-inline-block">
                          <h5 class="mb-0">

                            {{ $t('banmanager.view.verification.requirements.count.title') }}
                          </h5>
                          <span class="text-muted">
                                {{ $t('banmanager.view.verification.requirements.count.description') }}
                              </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col">
                      <div class="media">
                        <div class="d-inline mr-2">
                          <template v-if="verification.requirements.age">
                            <i class="fas fa-check text-success align-middle align-self-center font-size-24"></i>
                          </template>
                          <template v-else>
                            <i class="fas fa-times text-danger align-middle align-self-center font-size-24"></i>
                          </template>
                        </div>
                        <div class="d-inline-block">
                          <h5 class="mb-0">

                            {{ $t('banmanager.view.verification.requirements.age.title') }}
                          </h5>
                          <span class="text-muted">
                                {{ $t('banmanager.view.verification.requirements.age.description') }}
                              </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
